// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { withI18n, withI18nProps } from "@lingui/react";
import { toast } from "utils/Toast";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";

// Import types.
import TwoFactorType from "types/enums/TwoFactorType";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import User from "types/common/User";

// Import components.
import { Divider } from "@mui/material";
import Button from "components/common/button/Button";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import Tabs, { TabConfig } from "components/common/tabs";
import Information from "./Information";
import Password from "./Password";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import APIKeys from "./APIKeys";
import Permissions from "./Permissions";
import Settings from "./Settings";
import CustomData from "./CustomData";

// Import services.
import Services from "../services";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";
import * as Patterns from "utils/Patterns";
import LocalizationUtils from "utils/Localization";

// Define the properties accepted by this component.
interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    session: Session;
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
}
interface OWN_PROPS {
    preTeamSelection: boolean;
    context: "self" | "super" | "team";
    type: "basic" | "security" | "all";
    initialTabId?: "information" | "password" | "two-factor" | "api-keys" | "permissions" | "settings" | "custom-data" | null;
    buttonAlignment: "vertical" | "horizontal";
    email: string;
    onClose: () => void;
    onChangesApplied?: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, withI18nProps, WithStyles<typeof styles> {}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        applicationInformation: state.applicationInformation,
        session: state.session,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
    };
};

interface PASSWORD_STATE {
    currentPassword: string | null;
    newPassword: string | null;
    confirmPassword: string | null;
}

interface TWO_FACTOR_STATE {
    enabled: boolean;
    type: TwoFactorType | null;
    step: "init" | "setup" | "tip" | "manage" | null;
    countryCode: string | null;
    phoneNumber: string | null;
    qrCodeUri: string | null;
    token: string | null;
}

interface API_KEYS_STATE {
    mode: "create" | null;
    description: string | null;
    scope: "superadmin" | "team" | "app" | "user" | null;
    expiry: "1" | "2" | "3" | null;
}

// Define the local state managed by this component.
interface STATE {
    ready: boolean;
    originalUser: User | null;
    user: User | null;

    activeTabIndex: number | null;
    activeTabId?: string | null;

    passwordState: PASSWORD_STATE;
    twoFactorState: TWO_FACTOR_STATE;
    apiKeysState: API_KEYS_STATE;
}

class UserProfile extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        ready: false,
        originalUser: null,
        user: null,

        activeTabIndex: 0,
        activeTabId: "information",

        passwordState: {
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
        },
        twoFactorState: {
            enabled: false,
            type: null,
            step: null,
            countryCode: null,
            phoneNumber: null,
            qrCodeUri: null,
            token: null,
        },
        apiKeysState: {
            mode: null,
            description: null,
            scope: null,
            expiry: null,
        },
    };

    componentDidMount = () => {
        this.loadData(true);
    };

    componentDidUpdate = (preProps: PROPS) => {
        if (preProps.email !== this.props.email) {
            this.loadData(true);
        }
    };

    loadData = async (resetActiveTab: boolean) => {
        const { applicationInformation, session, preTeamSelection, context, email } = this.props;

        try {
            this.setState({ ready: false });

            const promiseBatch = await Promise.all([Services.getUser(email, context === "super" || (context === "self" && session.isSuper === true), preTeamSelection)]);

            const [originalUser] = promiseBatch;

            const user = CloneUtils.clone(originalUser) as User;

            const twoFactorType = !user.twoFactorAuthentication.enabled
                ? applicationInformation.twoFactorConfiguration.verifyAvailable
                    ? TwoFactorType.VERIFY
                    : applicationInformation.twoFactorConfiguration.authyAvailable
                    ? TwoFactorType.AUTHY
                    : null
                : applicationInformation.twoFactorConfiguration.verifyAvailable && user.twoFactorAuthentication.verifyId
                ? TwoFactorType.VERIFY
                : applicationInformation.twoFactorConfiguration.authyAvailable && user.twoFactorAuthentication.authyId
                ? TwoFactorType.AUTHY
                : null;
            const twoFactorId = twoFactorType === TwoFactorType.VERIFY ? user.twoFactorAuthentication.verifyId : twoFactorType === TwoFactorType.AUTHY ? user.twoFactorAuthentication.authyId : null;

            this.setState(
                {
                    ready: true,
                    originalUser: originalUser,
                    user: user,
                    passwordState: {
                        currentPassword: null,
                        newPassword: null,
                        confirmPassword: null,
                    },
                    twoFactorState: {
                        enabled: user.twoFactorAuthentication.enabled,
                        type: twoFactorType,
                        step: twoFactorId && !user.twoFactorAuthentication.pending ? "tip" : user.twoFactorAuthentication.pending ? "manage" : "init",
                        countryCode: user.twoFactorAuthentication.countryCode ? user.twoFactorAuthentication.countryCode : "1",
                        phoneNumber: user.twoFactorAuthentication.phoneNumber,
                        qrCodeUri: null,
                        token: null,
                    },
                    apiKeysState: {
                        mode: null,
                        description: null,
                        scope: null,
                        expiry: null,
                    },
                },
                () => {
                    if (this.state.twoFactorState.type === TwoFactorType.VERIFY && this.state.originalUser?.twoFactorAuthentication.verifyId && this.state.originalUser.twoFactorAuthentication.pending) {
                        // Use already linked their Veify 2FA and are still pending.
                        this.onLink2FAClicked();
                    }

                    if (resetActiveTab) {
                        this.resetActiveTab();
                    }
                }
            );
        } catch (error: any) {
            toast.error(error);

            this.props.onClose();
        }
    };

    resetActiveTab = () => {
        const { type, initialTabId } = this.props;

        if (initialTabId) {
            if (type === "all") {
                if (initialTabId === "information") this.setState({ activeTabId: "information", activeTabIndex: 0 });
                else if (initialTabId === "password") this.setState({ activeTabId: "password", activeTabIndex: 1 });
                else if (initialTabId === "two-factor") this.setState({ activeTabId: "two-factor", activeTabIndex: 2 });
                else if (initialTabId === "api-keys") this.setState({ activeTabId: "api-keys", activeTabIndex: 3 });
                else if (initialTabId === "permissions") this.setState({ activeTabId: "permissions", activeTabIndex: 4 });
                else if (initialTabId === "settings") this.setState({ activeTabId: "settings", activeTabIndex: 5 });
                else if (initialTabId === "custom-data") this.setState({ activeTabId: "custom-data", activeTabIndex: 6 });
                else this.setState({ activeTabId: "information", activeTabIndex: 0 });

                return;
            }

            if (type === "basic") {
                if (initialTabId === "information") this.setState({ activeTabId: "information", activeTabIndex: 0 });
                else if (initialTabId === "settings") this.setState({ activeTabId: "settings", activeTabIndex: 1 });
                else if (initialTabId === "custom-data") this.setState({ activeTabId: "custom-data", activeTabIndex: 2 });
                else this.setState({ activeTabId: "information", activeTabIndex: 0 });

                return;
            }

            if (type === "security") {
                if (initialTabId === "password") this.setState({ activeTabId: "password", activeTabIndex: 0 });
                else if (initialTabId === "two-factor") this.setState({ activeTabId: "two-factor", activeTabIndex: 1 });
                else if (initialTabId === "api-keys") this.setState({ activeTabId: "api-keys", activeTabIndex: 2 });
                else if (initialTabId === "permissions") this.setState({ activeTabId: "permissions", activeTabIndex: 3 });
                else this.setState({ activeTabId: "password", activeTabIndex: 0 });

                return;
            }
        }
    };

    handleTabChanged = (index: number, id?: string | null) => {
        this.setState({ activeTabIndex: index, activeTabId: id });
    };

    handleInformationChanged = (fullName: string | null, email: string | null) => {
        const { user } = this.state;

        const updatedUser = CloneUtils.clone(user) as User;

        if (fullName != null) updatedUser.fullName = fullName;
        if (email != null) updatedUser.email = email;

        this.setState({ user: updatedUser });
    };

    handlePasswordChanged = (currentPassword: string | null, newPassword: string | null, confirmPassword: string | null) => {
        const { passwordState } = this.state;

        const updatedPasswordState = CloneUtils.clone(passwordState) as PASSWORD_STATE;

        if (currentPassword != null) updatedPasswordState.currentPassword = currentPassword;
        if (newPassword != null) updatedPasswordState.newPassword = newPassword;
        if (confirmPassword != null) updatedPasswordState.confirmPassword = confirmPassword;

        this.setState({ passwordState: updatedPasswordState });
    };

    handleSendResetPasswordEmail = async () => {
        const { user } = this.state;

        if (user && user.email) {
            try {
                await Services.sendResetPasswordEmail(user.email);

                toast.success(<Trans>Successfully sent password reset email.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    handleResendActivationEmail = async () => {
        const { user } = this.state;

        if (user && user.email) {
            try {
                await Services.resendActivationEmail(user.email);

                toast.success(<Trans>Successfully sent activation email.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    handleTwoFactorAuthenticationChanged = (countryCode: string | null, phoneNumber: string | null, qrCodeUri: string | null, token: string | null) => {
        const { twoFactorState } = this.state;

        const updatedTwoFactorState = CloneUtils.clone(twoFactorState) as TWO_FACTOR_STATE;

        if (countryCode != null) updatedTwoFactorState.countryCode = countryCode;
        if (phoneNumber != null) updatedTwoFactorState.phoneNumber = phoneNumber;
        if (qrCodeUri != null) updatedTwoFactorState.qrCodeUri = qrCodeUri;
        if (token != null) updatedTwoFactorState.token = token;

        this.setState({ twoFactorState: updatedTwoFactorState });
    };

    handleApiKeysChanged = (description: string | null, scope: "superadmin" | "team" | "app" | "user" | null, expiry: "1" | "2" | "3" | null) => {
        const { apiKeysState } = this.state;

        const updatedApiKeysState = CloneUtils.clone(apiKeysState) as API_KEYS_STATE;

        if (description != null) updatedApiKeysState.description = description;
        if (scope != null) updatedApiKeysState.scope = scope;
        if (expiry != null) updatedApiKeysState.expiry = expiry;

        this.setState({ apiKeysState: updatedApiKeysState });
    };

    handlePermissionsChanged = (isTeamAdmin: boolean | null, hasApiAccess: boolean | null) => {
        const { user } = this.state;

        const updatedUser = CloneUtils.clone(user) as User;

        if (isTeamAdmin != null) updatedUser.isTeamAdmin = isTeamAdmin;
        if (hasApiAccess != null) updatedUser.builderApi.enabled = hasApiAccess;

        this.setState({ user: updatedUser });
    };

    handleSettingsChanged = (name: string, value: any) => {
        const { user } = this.state;

        const updatedUser = CloneUtils.clone(user) as User;

        switch (name) {
            case "language":
                updatedUser.preferredLanguage = value;
                break;
            case "country":
                updatedUser.preferredCountry = value;
                break;
            case "timezone":
                updatedUser.preferredTimezone = value;
                break;
            case "themeMode":
                updatedUser.preferredThemeMode = value;
                break;
            case "pageSize":
                if (!updatedUser.customData) {
                    updatedUser.customData = {};
                }
                if (value) {
                    updatedUser.customData.pageSize = value;
                } else {
                    delete updatedUser.customData.pageSize;
                }
                break;
            default:
            // Do nothing.
        }

        this.setState({ user: updatedUser });
    };

    handleCustomDataChanged = (name: string, value: any) => {
        const { user } = this.state;

        const updatedUser = CloneUtils.clone(user) as User;

        if (updatedUser.customData == null) {
            updatedUser.customData = {};
        }

        updatedUser.customData[name] = value;

        this.setState({ user: updatedUser });
    };

    handleKeyPress = (name: string, key: string) => {
        const { twoFactorState, activeTabId, ready } = this.state;

        if (ready && activeTabId && ["two-factor"].includes(activeTabId) && this.isActiveTabValid() && ["phoneNumber", "token"].includes(name) && key === "Enter") {
            switch (twoFactorState.step) {
                case "setup":
                    if (ready && this.isActiveTabValid()) this.onLink2FAClicked();

                    break;
                case "manage":
                    if (ready && this.isActiveTabValid()) this.onVerify2FAClicked();

                    break;
                default:
                // Do nothing
            }
        }
    };

    isActiveTabValid() {
        const { activeTabId, originalUser, user, passwordState, twoFactorState, apiKeysState } = this.state;

        if (user) {
            switch (activeTabId) {
                case "password":
                    const { currentPassword, newPassword, confirmPassword } = passwordState;

                    return !StringUtils.isNullOrEmpty(currentPassword) && !StringUtils.isNullOrEmpty(newPassword) && !StringUtils.isNullOrEmpty(confirmPassword) && newPassword === confirmPassword && currentPassword !== newPassword;
                case "two-factor":
                    const { step, countryCode, phoneNumber, token } = twoFactorState;

                    if (step === "setup") {
                        switch (twoFactorState.type) {
                            case TwoFactorType.VERIFY:
                                return true;
                            case TwoFactorType.AUTHY:
                                return !StringUtils.isNullOrEmpty(countryCode) && !StringUtils.isNullOrEmpty(phoneNumber);
                            default:
                                return false;
                        }
                    } else {
                        if (step === "manage") {
                            return !StringUtils.isNullOrEmpty(token);
                        }
                    }

                    break;
                case "api-keys":
                    const { mode, description, scope, expiry } = apiKeysState;

                    if (mode === "create") {
                        return !StringUtils.isNullOrEmpty(description) && !StringUtils.isNullOrEmpty(scope) && !StringUtils.isNullOrEmpty(expiry);
                    }

                    break;
                case "permissions":
                    if (originalUser) {
                        if (originalUser.isTeamAdmin === user.isTeamAdmin && originalUser.builderApi.enabled === user.builderApi.enabled) return false;
                    }

                    break;

                default:
                // Do nothing.
            }
        }

        return true;
    }

    isUserDataSaveValid() {
        const { originalUser, user } = this.state;
        if (user) {
            if (StringUtils.isNullOrEmpty(user.email) || !Patterns.EMAIL.test(user.email || "")) return false;
            if (StringUtils.isNullOrEmpty(user.fullName)) return false;
            if (originalUser) {
                if (
                    originalUser.email === user.email &&
                    originalUser.fullName === user.fullName &&
                    originalUser.preferredLanguage === user.preferredLanguage &&
                    originalUser.preferredCountry === user.preferredCountry &&
                    originalUser.preferredTimezone === user.preferredTimezone &&
                    originalUser.preferredThemeMode === user.preferredThemeMode
                )
                    return false;
            }
        }
        return true;
    }

    isSaveValid() {
        const { originalUser, user } = this.state;

        if (user && originalUser) {
            if (JSON.stringify(originalUser.customData) !== JSON.stringify(user.customData)) return true;
        }
        if (this.isUserDataSaveValid()) return true;

        return false;
    }

    onSetup2FAClicked = (type?: TwoFactorType) => {
        const { twoFactorState } = this.state;

        const updatedTwoFactorState = CloneUtils.clone(twoFactorState) as TWO_FACTOR_STATE;

        if (type) {
            updatedTwoFactorState.type = type;
        }

        updatedTwoFactorState.step = "setup";

        this.setState({ twoFactorState: updatedTwoFactorState });
    };

    onAbort2FAClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type) {
            try {
                await Services.unlinkTwoFactorAuthentication(twoFactorState.type, originalUser.email, false);

                const updatedTwoFactorState = CloneUtils.clone(twoFactorState) as TWO_FACTOR_STATE;

                updatedTwoFactorState.step = "init";
                updatedTwoFactorState.countryCode = originalUser.twoFactorAuthentication.countryCode ? originalUser.twoFactorAuthentication.countryCode : "1";
                updatedTwoFactorState.phoneNumber = originalUser.twoFactorAuthentication.phoneNumber;
                updatedTwoFactorState.qrCodeUri = null;
                updatedTwoFactorState.token = null;

                this.setState({ twoFactorState: updatedTwoFactorState });
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onLink2FAClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type) {
            try {
                const qrCodeUri = await Services.linkTwoFactorAuthentication(twoFactorState.type, originalUser.email, twoFactorState.countryCode, twoFactorState.phoneNumber);

                const updatedTwoFactorState = CloneUtils.clone(twoFactorState) as TWO_FACTOR_STATE;

                updatedTwoFactorState.step = "manage";
                updatedTwoFactorState.qrCodeUri = qrCodeUri;

                this.setState({ twoFactorState: updatedTwoFactorState });
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onUnlink2FAClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type) {
            try {
                await Services.unlinkTwoFactorAuthentication(twoFactorState.type, originalUser.email, true);

                await this.loadData(false);

                if (this.props.onChangesApplied) this.props.onChangesApplied();
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onResendSMSClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type) {
            try {
                await Services.sendTwoFactorAuthenticationSMS(twoFactorState.type, originalUser.email);

                toast.success(<Trans>Successfully sent SMS.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onVerify2FAClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type && twoFactorState.token) {
            try {
                await Services.verifyTwoFactorAuthentication(twoFactorState.type, originalUser.email, twoFactorState.token);

                try {
                    await Services.clearTwoFactorAuthenticationDevices(twoFactorState.type, originalUser.email);
                } catch (error: any) {
                    toast.error(error);
                }

                await this.loadData(false);

                if (this.props.onChangesApplied) this.props.onChangesApplied();
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onClearDevicesClicked = async () => {
        const { originalUser, twoFactorState } = this.state;

        if (originalUser && originalUser.email && twoFactorState.type) {
            try {
                await Services.clearTwoFactorAuthenticationDevices(twoFactorState.type, originalUser.email);

                toast.success(<Trans>Successfully cleared devices.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onGetApiKeyClicked = (apiKeyId: string) => {
        const { session } = this.props;
        const { originalUser } = this.state;

        if (originalUser) {
            ClipboardUtils.writeTextAsync(
                new Promise((resolve, reject) => {
                    Services.getApiKey(originalUser.profileId, apiKeyId, session.isSuper === true)
                        .then((result) => {
                            resolve(result?.apiKey);
                        })
                        .catch((e) => {
                            reject(e);
                        });
                })
            );
        }
    };

    onCreateApiKeyClicked = () => {
        const { apiKeysState } = this.state;

        const updatedApiKeysState = CloneUtils.clone(apiKeysState) as API_KEYS_STATE;

        updatedApiKeysState.mode = "create";
        updatedApiKeysState.description = null;
        updatedApiKeysState.scope = "team";
        updatedApiKeysState.expiry = "1";

        this.setState({ apiKeysState: updatedApiKeysState });
    };

    onConfirmCreateApiKeyClicked = async () => {
        const { session } = this.props;
        const { originalUser, apiKeysState } = this.state;
        const { description, scope, expiry } = apiKeysState;

        if (originalUser && description && scope && expiry) {
            try {
                const updatedApiKeys = await Services.createApiKey(originalUser.profileId, description, scope, expiry, session.isSuper === true);

                const updatedOriginalUser = CloneUtils.clone(originalUser) as User;

                updatedOriginalUser.builderApi.apiKeys = updatedApiKeys;

                this.setState({ originalUser: updatedOriginalUser, user: CloneUtils.clone(updatedOriginalUser) as User });

                const updatedApiKeysState = CloneUtils.clone(apiKeysState) as API_KEYS_STATE;

                updatedApiKeysState.mode = null;
                updatedApiKeysState.description = null;
                updatedApiKeysState.scope = null;
                updatedApiKeysState.expiry = null;

                this.setState({ apiKeysState: updatedApiKeysState });

                toast.success(<Trans>Successfully created API key.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onCancelCreateApiKeyClicked = () => {
        const { apiKeysState } = this.state;

        const updatedApiKeysState = CloneUtils.clone(apiKeysState) as API_KEYS_STATE;

        updatedApiKeysState.mode = null;
        updatedApiKeysState.description = null;
        updatedApiKeysState.scope = null;
        updatedApiKeysState.expiry = null;

        this.setState({ apiKeysState: updatedApiKeysState });
    };

    onDeleteApiKeyClicked = async (apiKeyId: string) => {
        const { session } = this.props;
        const { originalUser } = this.state;

        if (originalUser && apiKeyId) {
            try {
                const updatedApiKeys = await Services.deleteApiKey(originalUser.profileId, apiKeyId, session.isSuper === true);

                const updatedOriginalUser = CloneUtils.clone(originalUser) as User;

                updatedOriginalUser.builderApi.apiKeys = updatedApiKeys;

                this.setState({ originalUser: updatedOriginalUser, user: CloneUtils.clone(updatedOriginalUser) as User });

                toast.success(<Trans>Successfully deleted API key.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    onApplyClicked = async () => {
        const { session, context } = this.props;
        const { activeTabId, originalUser, user, passwordState } = this.state;
        const { currentPassword, newPassword, confirmPassword } = passwordState;

        if (user) {
            const updatedOriginalUser = CloneUtils.clone(originalUser) as User;

            switch (activeTabId) {
                case "password":
                    if (currentPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
                        try {
                            await Services.updateUserPassword(user.profileId, currentPassword, newPassword);

                            const updatedPasswordState = { currentPassword: null, newPassword: null, confirmPassword: null };

                            this.setState({ passwordState: updatedPasswordState });

                            toast.success(<Trans>Successfully updated user password.</Trans>);
                        } catch (error: any) {
                            toast.error(error);
                        }
                    }

                    break;
                case "permissions":
                    try {
                        await Services.updateUserAccess(user.profileId, user.isTeamAdmin, user.builderApi.enabled, context === "super" || (context === "self" && session.isSuper));

                        // Update the original user so the "apply" button gets disabled after the save has completed.
                        updatedOriginalUser.isTeamAdmin = user.isTeamAdmin;
                        updatedOriginalUser.builderApi.enabled = user.builderApi.enabled;

                        this.setState({ originalUser: updatedOriginalUser });

                        toast.success(<Trans>Successfully updated user permissions.</Trans>);
                    } catch (error: any) {
                        toast.error(error);
                    }

                    break;
                default:
                // Do nothing.
            }
        }

        if (this.props.onChangesApplied) this.props.onChangesApplied();
    };

    onSaveClicked = async () => {
        const { session, i18n } = this.props;
        const { originalUser, user } = this.state;

        if (user) {
            try {
                if (this.isUserDataSaveValid()) {
                    await Services.updateUserDetails(user.profileId, user.email, user.fullName, user.preferredLanguage, user.preferredCountry, user.preferredTimezone, user.preferredThemeMode);
                }
                if (JSON.stringify(originalUser?.customData) !== JSON.stringify(user.customData)) {
                    await Services.updateUserCustomData(user.profileId, user.customData);
                }

                //Update the user-selected language in the activeLanguage property of LocalizationUtils.

                const activeLanguage = LocalizationUtils.getActiveLanguage(i18n);
                if (activeLanguage !== user.preferredLanguage) {
                    await LocalizationUtils.setActiveLanguage(i18n, user.preferredLanguage);
                }

                // Update the original user after the save has completed, so the "save" button gets disabled.

                this.setState({ originalUser: user });

                toast.success(<Trans>Successfully updated User Profile.</Trans>);
            } catch (error: any) {
                toast.error(error);
            }
        }

        if (this.props.onChangesApplied) {
            // If the user is changing their own email address, then we need to update the current session to reflect the new value.
            // The current user information will be refreshed as a result and we need the correct email address associated with the session for that to work.
            // Otherwise that call would fail and our session would be out-of-sync with the actual user data.
            if (originalUser && user && originalUser.email !== user.email && originalUser.email === session.email) {
                const updatedSession = CloneUtils.clone(session) as Session;

                updatedSession.email = user.email;

                // Update the session in the redux store.
                this.props.setSession(updatedSession);
                this.props.saveSession();
            }

            this.props.onChangesApplied();
        }
    };

    prepareTabConfigs(): TabConfig[] {
        const { applicationInformation, session, context, type } = this.props;
        const { originalUser, user, passwordState, twoFactorState, apiKeysState } = this.state;

        const tabConfigs = [];

        if (originalUser && user) {
            // User Profile - Information
            if (["basic", "all"].includes(type)) {
                tabConfigs.push({
                    id: "information",
                    label: <Trans>Info</Trans>,
                    component: (
                        <Information
                            context={context}
                            fullName={user.fullName}
                            email={user.email}
                            createdAt={user.createdAt}
                            lastLogin={user.lastLogin}
                            isActive={user.isActive}
                            badLoginCount={user.badLoginCount}
                            isLocked={user.isLocked}
                            isCurrentSessionUser={session.email === originalUser.email}
                            onChange={this.handleInformationChanged}
                            onSendResetPasswordEmail={this.handleSendResetPasswordEmail}
                            onResendActivationEmail={this.handleResendActivationEmail}
                        />
                    ),
                });
            }

            // User Profile - Password
            if (["security", "all"].includes(type) && (context === "self" || session.email === originalUser.email)) {
                tabConfigs.push({
                    id: "password",
                    label: <Trans>Password</Trans>,
                    component: <Password currentPassword={passwordState.currentPassword} newPassword={passwordState.newPassword} confirmPassword={passwordState.confirmPassword} onChange={this.handlePasswordChanged} />,
                });
            }

            // User Profile - Two-Factor Authentication (if enabled)
            if (["security", "all"].includes(type) && (context === "self" || session.email === originalUser.email || (session.isSuper && session.isTeamAdmin)) && applicationInformation.twoFactorConfiguration.enabled) {
                tabConfigs.push({
                    id: "two-factor",
                    label: <Trans>MFA</Trans>,
                    component: (
                        <TwoFactorAuthentication
                            type={twoFactorState.type}
                            step={twoFactorState.step}
                            countryCode={twoFactorState.countryCode}
                            phoneNumber={twoFactorState.phoneNumber}
                            qrCodeUri={twoFactorState.qrCodeUri}
                            token={twoFactorState.token}
                            showAuthyWarning={user.twoFactorAuthentication.authyId != null && user.twoFactorAuthentication.verifyId == null}
                            onChange={this.handleTwoFactorAuthenticationChanged}
                            onKeyPress={this.handleKeyPress}
                        />
                    ),
                });
            }

            // User Profile - Builder API (if enabled)
            if (["security", "all"].includes(type) && (context === "self" || session.email === originalUser.email) && session.companyId) {
                tabConfigs.push({
                    id: "api-keys",
                    label: <Trans>API Keys</Trans>,
                    component: (
                        <APIKeys
                            context={context}
                            apiKeys={user.builderApi.apiKeys}
                            mode={apiKeysState.mode}
                            description={apiKeysState.description}
                            scope={apiKeysState.scope}
                            expiry={apiKeysState.expiry}
                            onGet={this.onGetApiKeyClicked}
                            onCreate={this.onCreateApiKeyClicked}
                            onDelete={this.onDeleteApiKeyClicked}
                            onChange={this.handleApiKeysChanged}
                        />
                    ),
                });
            }

            // User Profile - Permissions
            if (["security", "all"].includes(type) && (context === "self" || session.email === originalUser.email || (context === "team" && (session.isSuper || session.isTeamAdmin)) || (context === "super" && session.isSuper)) && session.companyId) {
                tabConfigs.push({
                    id: "permissions",
                    label: <Trans>Permissions</Trans>,
                    component: (
                        <Permissions
                            context={context}
                            isTeamAdmin={user.isTeamAdmin}
                            hasApiAccess={user.builderApi.enabled}
                            appRoles={user.appRoles}
                            isCurrentSessionUser={session.email === originalUser.email}
                            onChange={this.handlePermissionsChanged}
                        />
                    ),
                });
            }

            // User Profile - Settings
            if (["basic", "all"].includes(type) && (context === "self" || session.email === originalUser.email)) {
                tabConfigs.push({
                    id: "settings",
                    label: <Trans>Settings</Trans>,
                    component: (
                        <Settings
                            language={user.preferredLanguage}
                            country={user.preferredCountry}
                            timezone={user.preferredTimezone}
                            pageSize={user.customData?.pageSize}
                            isCurrentSessionUser={session.email === originalUser.email}
                            onChange={this.handleSettingsChanged}
                        />
                    ),
                });
            }

            // User Profile - Custom Data (visible to SUPER's only and only editable for SUPER ADMIN's).
            if (["basic", "all"].includes(type) && session.isSuper) {
                tabConfigs.push({
                    id: "custom-data",
                    label: <Trans>Custom Data</Trans>,
                    component: <CustomData portalxAccessPermitted={user.customData?.portalxAccessPermitted} disabled={!session.isTeamAdmin} onChange={this.handleCustomDataChanged} />,
                });
            }
        }

        return tabConfigs;
    }

    render() {
        const { classes, applicationInformation, session, context, buttonAlignment } = this.props;
        const { ready, activeTabId, originalUser, activeTabIndex, twoFactorState, apiKeysState, user } = this.state;

        if (!ready) {
            return <LoadingProgress />;
        }

        return (
            <div className={classes.root}>
                <Tabs className={classes.tabs} orientation={"horizontal"} configs={this.prepareTabConfigs()} value={activeTabIndex} onTabChanged={this.handleTabChanged} />

                <Divider className={classes.divider} />

                <div className={buttonAlignment === "vertical" ? classes.verticalButtons : classes.horizontalButtons}>
                    {buttonAlignment === "horizontal" && !(activeTabId === "api-keys" && apiKeysState.mode === "create" && context === "self") && (
                        <Button id={"cancel"} type={"neutral"} onClick={() => this.props.onClose()} disabled={!ready}>
                            <Trans>Cancel</Trans>
                        </Button>
                    )}

                    {activeTabId != null && ["information", "settings", "custom-data"].includes(activeTabId) && (
                        <Button id={"confirm"} type={"primary"} onClick={this.onSaveClicked} disabled={!ready || !this.isSaveValid()}>
                            <Trans>Save</Trans>
                        </Button>
                    )}

                    {activeTabId != null && ["password", "permissions"].includes(activeTabId) && (
                        <Button id={"apply"} type={"primary"} onClick={this.onApplyClicked} disabled={!ready || !this.isActiveTabValid()}>
                            <Trans>Apply</Trans>
                        </Button>
                    )}

                    {activeTabId === "two-factor" && twoFactorState.step === "init" && applicationInformation.twoFactorConfiguration.enabled && (
                        <span>
                            {(context === "self" || session.email === originalUser?.email) && (
                                <Button id={"setup-2fa"} type={"primary"} onClick={() => this.onSetup2FAClicked()} disabled={!ready}>
                                    <Trans>Setup MFA</Trans>
                                </Button>
                            )}
                        </span>
                    )}

                    {activeTabId === "two-factor" && twoFactorState.step === "setup" && applicationInformation.twoFactorConfiguration.enabled && (
                        <Button id={"link"} type={"primary"} onClick={this.onLink2FAClicked} disabled={!ready || !this.isActiveTabValid()}>
                            <Trans>Link</Trans>
                        </Button>
                    )}

                    {activeTabId === "two-factor" && twoFactorState.step === "manage" && applicationInformation.twoFactorConfiguration.enabled && (
                        <span>
                            <Button id={"abort"} type={"semantic-negative-primary"} onClick={this.onAbort2FAClicked} disabled={!ready}>
                                <Trans>Abort</Trans>
                            </Button>

                            {applicationInformation.twoFactorConfiguration.authyAvailable && twoFactorState.type === TwoFactorType.AUTHY && (
                                <Button id={"send-sms"} type={"secondary"} onClick={this.onResendSMSClicked} disabled={!ready}>
                                    <Trans>Resend SMS</Trans>
                                </Button>
                            )}

                            <Button id={"verify"} type={"primary"} onClick={this.onVerify2FAClicked} disabled={!ready || !this.isActiveTabValid()}>
                                <Trans>Verify</Trans>
                            </Button>
                        </span>
                    )}

                    {activeTabId === "two-factor" && twoFactorState.step === "tip" && applicationInformation.twoFactorConfiguration.enabled && (
                        <span>
                            {(context === "self" || session.email === originalUser?.email || (session.isSuper && session.isTeamAdmin)) && (
                                <>
                                    <Button id={"clear-devices"} type={"secondary"} onClick={this.onClearDevicesClicked} disabled={!ready}>
                                        <Trans>Clear Devices</Trans>
                                    </Button>

                                    <Button id={"unlink"} type={"semantic-negative-primary"} onClick={this.onUnlink2FAClicked} disabled={!ready}>
                                        <Trans>Unlink</Trans>
                                    </Button>
                                </>
                            )}

                            {user?.twoFactorAuthentication.authyId && !user?.twoFactorAuthentication.verifyId && applicationInformation.twoFactorConfiguration.verifyAvailable && (
                                <Button id={"setup-2fa-verify"} type={"primary"} onClick={() => this.onSetup2FAClicked(TwoFactorType.VERIFY)} disabled={!ready}>
                                    <Trans>Verify API</Trans>
                                </Button>
                            )}
                        </span>
                    )}

                    {activeTabId === "api-keys" && apiKeysState.mode == null && context === "self" && (
                        <Button id={"new-key"} type={"primary"} onClick={this.onCreateApiKeyClicked} disabled={!ready || !user?.builderApi.enabled || StringUtils.isNullOrEmpty(session.companyId)}>
                            <Trans>New Key</Trans>
                        </Button>
                    )}

                    {activeTabId === "api-keys" && apiKeysState.mode === "create" && context === "self" && (
                        <span>
                            <Button id={"cancel-create-key"} type={"secondary"} onClick={this.onCancelCreateApiKeyClicked} disabled={!ready}>
                                <Trans>Cancel</Trans>
                            </Button>

                            <Button id={"confirm-create-key"} type={"primary"} onClick={this.onConfirmCreateApiKeyClicked} disabled={!ready || !this.isActiveTabValid()}>
                                <Trans>Create Key</Trans>
                            </Button>
                        </span>
                    )}

                    {buttonAlignment === "vertical" && !(activeTabId === "api-keys" && apiKeysState.mode === "create" && context === "self") && (
                        <Button id={"cancel"} type={"neutral"} onClick={() => this.props.onClose()} disabled={!ready}>
                            <Trans>Cancel</Trans>
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

// Styling for this component.
const styles = () =>
    createStyles({
        root: {
            display: "flex",
            flex: "auto",
            flexDirection: "column",
            height: "100%",
            color: "inherit",
            borderColor: "inherit",
            backgroundColor: "inherit",
            overflow: "hidden",
        },
        columnBlock: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            color: "inherit",
            borderColor: "inherit",
            backgroundColor: "inherit",
        },
        tabs: {
            "& .MuiTabs-root": {
                minHeight: "unset",
                maxHeight: "unset",

                "& .MuiTab-root": {
                    minHeight: "unset",
                    maxHeight: "unset",

                    minWidth: "unset",
                    maxWidth: "unset",
                },
            },
        },
        divider: {
            backgroundColor: "var(--navigation-border-color, inherit)",
        },
        verticalButtons: {
            display: "flex",
            flexDirection: "column",
            margin: "auto 1em 1em 1em",

            "& > span": {
                display: "flex",
                flexDirection: "column",
            },
        },
        horizontalButtons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",

            "& > span": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(UserProfile)));
