// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Chip, ClickAwayListener, Link } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { I18n } from "@lingui/core";
import classnames from "classnames";

// Import types.
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import components.
import { Divider, MenuItem, Popover, Typography } from "@mui/material";
import Button from "components/common/button/Button";
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import UserProfile from "components/common/dialog/User/UserProfile";
import InfoBox from "components/common/screen/InfoBox";
import FlyOutPanel from "components/common/FlyOutPanel";

// Import icons.
import OpenIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/LockOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Import utilities.
import LocalStorageUtils from "utils/LocalStorage";
import CloneUtils from "utils/Clone";

interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
    populateCurrentUser: (i18n: I18n, basic: boolean) => void;
}
interface OWN_PROPS {
    selectTeamContainerRef: React.RefObject<HTMLDivElement>;
    onCreate: (companyId: string | null) => void;
    onComplete: (companyId: string | null) => void;
    onCancel: () => void;
    companyId?: string | null;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

interface STATE {
    teamSelectPopoverOpen: boolean;
    search: string;
    selectedCompanyId: string | null;
    panelType: "basic" | "security" | null;
    initialPanelTabId: "information" | "password" | "two-factor" | "api-keys" | "permissions" | "settings" | "custom-data" | null;
}

const mapStateToProps = (state: PortalState) => {
    return {
        applicationInformation: state.applicationInformation,
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
        populateCurrentUser: (i18n: I18n, basic: boolean) => dispatch({ type: "user.populateCurrentUser", payload: { i18n, basic } }),
    };
};

class SelectCompany extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        teamSelectPopoverOpen: false,
        search: "",
        selectedCompanyId: this.props.companyId || null,
        panelType: null,
        initialPanelTabId: null,
    };

    componentDidMount() {
        const { availableCompanies } = this.props;

        const otherCompanies = availableCompanies.filter((item) => !item.isSuper);

        if (otherCompanies.length > 0) {
            this.initializeSelectedCompanyId();
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (
            prevProps.availableCompanies !== this.props.availableCompanies ||
            prevProps.companyId !== this.props.companyId ||
            prevProps.applicationInformation.loadingLoginState !== this.props.applicationInformation.loadingLoginState ||
            prevProps.applicationInformation.loadingBasicState !== this.props.applicationInformation.loadingBasicState
        ) {
            this.initializeSelectedCompanyId();
        }
    }

    initializeSelectedCompanyId = () => {
        const { applicationInformation, session, currentUser, availableCompanies, companyId } = this.props;
        const { selectedCompanyId } = this.state;

        const currentUserProfileId = currentUser?.profileId || null;

        const otherCompanies = availableCompanies.filter((item) => !item.isSuper);

        const preSelectedCompany = otherCompanies.find((item) => (companyId ? item.companyId === companyId : item.companyId === selectedCompanyId)) || null;

        let autoSelectCompanyId: string | null = null;

        let lastTeamData: { companyId: string } | null = null;
        try {
            const lastTeamData_RAW = LocalStorageUtils.getItem("lastteam_" + currentUserProfileId);

            if (lastTeamData_RAW) {
                lastTeamData = JSON.parse(lastTeamData_RAW);
            }
        } catch (e) {}

        let lastTeamAutoSelectData: { companyId: string } | null = null;
        try {
            const lastTeamAutoSelectData_RAW = LocalStorageUtils.getItem("lastteam_autoselect_" + currentUserProfileId);

            if (lastTeamAutoSelectData_RAW) {
                lastTeamAutoSelectData = JSON.parse(lastTeamAutoSelectData_RAW);
            }
        } catch (e) {}

        autoSelectCompanyId = lastTeamAutoSelectData?.companyId || null;

        // Look up the initially selected team (falling back to the first available).
        const initialTeam = preSelectedCompany || otherCompanies.find((item) => item.companyId === autoSelectCompanyId) || (otherCompanies.length > 0 ? otherCompanies[0] : null);

        this.setState({ selectedCompanyId: initialTeam?.companyId || null }, () => {
            // If the session indicates that we should auto-complete the company selection, then attempt to do it now.
            if (session.autoCompleteCompanySelection && !applicationInformation.loadingLoginState && !applicationInformation.loadingBasicState) {
                this.autoCompleteCompanySelection(lastTeamData?.companyId);
            }
        });
    };

    autoCompleteCompanySelection = (companyId?: string | null) => {
        const { session, availableCompanies } = this.props;

        const updatedSession = CloneUtils.clone(session) as Session;

        delete updatedSession.autoCompleteCompanySelection;

        // Update the session in the redux store.
        this.props.setSession(updatedSession);
        this.props.saveSession();

        if (companyId) {
            console.log("Attempting to auto-complete company selection", companyId);

            const company = availableCompanies.find((item) => item.companyId === companyId);

            if (company) {
                this.props.onComplete(company.companyId);
            } else {
                console.log("Previously selected company is no longer valid", companyId);
            }
        }
    };

    openTeamSelectorPopover = () => {
        this.setState({ teamSelectPopoverOpen: true });
    };

    closeTeamSelectorPopover = () => {
        this.setState({ teamSelectPopoverOpen: false, search: "" });
    };

    setSearchFilter = (value: string) => {
        this.setState({ search: value });
    };

    setSelectedCompanyId = (companyId: string, autoContinue?: boolean) => {
        this.setState({ selectedCompanyId: companyId }, () => {
            if (autoContinue && this.isFormValid()) {
                this.props.onComplete(this.state.selectedCompanyId);
            }
        });
    };

    isFormValid = () => {
        const { availableCompanies } = this.props;
        const { selectedCompanyId } = this.state;

        const otherCompanies = availableCompanies.filter((item) => !item.isSuper);
        const otherCompany = otherCompanies.find((item) => item.companyId === selectedCompanyId) || null;

        if (otherCompany == null) return false;

        return true;
    };

    openEditDialog = (panelType: "basic" | "security", initialPanelTabId?: "information" | "password" | "two-factor" | "api-keys" | "permissions" | "settings" | "custom-data") => {
        this.setState({ panelType, initialPanelTabId: initialPanelTabId || null });
    };

    closeEditDialog = () => {
        this.setState({ panelType: null });
    };

    onProfileChanged = () => {
        this.props.populateCurrentUser(this.props.i18n, false);
    };

    render() {
        const { i18n, classes, applicationInformation, availableCompanies, selectTeamContainerRef, session, currentUser } = this.props;
        const { teamSelectPopoverOpen, search, selectedCompanyId, panelType, initialPanelTabId } = this.state;

        const superCompany = availableCompanies.find((item) => item.isSuper) || null;
        const otherCompanies = availableCompanies.filter((item) => !item.isSuper);
        const selectedCompany = otherCompanies.find((item) => item.companyId === selectedCompanyId) || null;

        const filtered = otherCompanies.filter((item) => item.companyName.toLowerCase().includes(search.toLowerCase()) || item.accountNumber?.toLowerCase().startsWith(search.toLowerCase()));

        return (
            <div id={"select-team"} className={classes.root}>
                {applicationInformation.disabled && (
                    <InfoBox
                        style={{ marginBottom: "2em" }}
                        type={"warning"}
                        text={
                            <Typography style={{ fontSize: "1.25rem", textAlign: "center" }}>
                                {!superCompany ? <Trans>Portal has been temporarily disabled. Please try again later.</Trans> : <Trans>Portal has been temporarily disabled for non-super users.</Trans>}
                            </Typography>
                        }
                    />
                )}

                {!applicationInformation.disabled && (
                    <>
                        {otherCompanies.length === 0 && (
                            <>
                                <Typography style={{ alignSelf: "center", margin: "0.3125rem" }}>
                                    {applicationInformation.memberCanCreateTeam ? <Trans>You don’t have a Team yet. Let’s create a new team to start your brainCloud journey.</Trans> : <Trans>You don’t have a Team yet.</Trans>}
                                </Typography>

                                {applicationInformation.memberCanCreateTeam && (
                                    <Button className={classes.button} id={"create-new-team"} type={"primary"} onClick={() => this.props.onCreate(selectedCompanyId)}>
                                        <Trans>Create New Team</Trans>
                                    </Button>
                                )}
                            </>
                        )}

                        {otherCompanies.length > 0 && (
                            <>
                                <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                                    <Trans>Select Team</Trans>
                                </Typography>

                                <Button className={classes.teamSelectorButton} id={"open-team-selector"} type={"secondary"} onClick={this.openTeamSelectorPopover}>
                                    {!applicationInformation.loadingLoginState && !applicationInformation.loadingBasicState && (
                                        <span style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", overflow: "hidden" }}>
                                            <img
                                                style={{ flex: "0 0 auto", width: "1.5rem", height: "1.5rem", borderRadius: "50%", overflow: "hidden" }}
                                                src={selectedCompany?.imageUrl ? "files/portal/c/" + selectedCompany.companyId + selectedCompany.imageUrl : "images/teamDefault_logo.png"}
                                                alt={"other-company"}
                                            ></img>

                                            <Typography style={{ flex: "1 1 auto", marginLeft: "0.625rem", marginRight: "0.3125rem" }} noWrap>
                                                {selectedCompany?.companyName || <Trans>No Team Available</Trans>}
                                            </Typography>

                                            <OpenIcon style={{ flex: "0 0 auto", height: "100%", marginLeft: "auto" }} />
                                        </span>
                                    )}
                                </Button>

                                <Button
                                    className={classes.button}
                                    id={"select-other-team"}
                                    type={"primary"}
                                    onClick={() => this.props.onComplete(selectedCompanyId)}
                                    disabled={!this.isFormValid() || (selectedCompany?.requireMFA && session.authType !== "external" && !currentUser?.twoFactorAuthentication.enabled)}
                                >
                                    <Trans>Login to Team</Trans>
                                </Button>

                                {selectedCompany?.requireMFA && session.authType !== "external" && !currentUser?.twoFactorAuthentication.enabled && (
                                    <Chip
                                        className={classes.chip}
                                        style={{ height: "auto" }}
                                        label={
                                            <Typography style={{ whiteSpace: "break-spaces", textAlign: "center", margin: "0.3125em" }}>
                                                <Trans>
                                                    Access denied. This team requires MFA be enabled on your user account. Edit your security settings to{" "}
                                                    {
                                                        <Link onClick={() => this.openEditDialog("security", "two-factor")} style={{ color: "var(--portal-color-yellow)", fontWeight: "bold" }}>
                                                            <Trans>Enable MFA</Trans>
                                                        </Link>
                                                    }
                                                    .
                                                </Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </>
                        )}
                    </>
                )}

                {superCompany && (
                    <>
                        <span style={{ flex: "0 0 auto", display: "flex", position: "relative", marginTop: "1rem", marginBottom: "1rem", backgroundColor: "inherit", color: "inherit", borderColor: "inherit" }}>
                            <Divider style={{ position: "absolute", width: "100%", top: "50%", left: 0, backgroundColor: "var(--secondary-border-color, inherit)" }} />

                            <Typography style={{ flex: "0 0 auto", zIndex: 1, marginLeft: "auto", marginRight: "auto", paddingLeft: "0.5rem", paddingRight: "0.5rem", backgroundColor: "inherit" }}>
                                <Trans>OR</Trans>
                            </Typography>
                        </span>

                        <Button className={classes.button} id={"select-super-team"} type={"semantic-negative-primary"} onClick={() => this.props.onComplete(superCompany.companyId)}>
                            <Trans>Continue as Super User</Trans>
                        </Button>
                    </>
                )}

                <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    {!applicationInformation.disabled && (
                        <>
                            <span className={classes.iconLinkPair}>
                                <IconButton id={"edit-profile-button"} className={classes.icon} onClick={() => this.openEditDialog("basic")}>
                                    <EditIcon />
                                </IconButton>
                                <Typography style={{ fontSize: "0.75rem" }}>
                                    <Trans>Edit Profile</Trans>
                                </Typography>
                            </span>

                            <span className={classes.iconLinkPair}>
                                <IconButton id={"edit-security-button"} className={classes.icon} onClick={() => this.openEditDialog("security")}>
                                    <LockIcon />
                                </IconButton>
                                <Typography style={{ fontSize: "0.75rem" }}>
                                    <Trans>Edit Security</Trans>
                                </Typography>
                            </span>
                        </>
                    )}
                </div>

                <Button className={classes.button} id={"logout-button"} type={"secondary"} onClick={this.props.onCancel}>
                    <Trans>Log out</Trans>
                </Button>

                <FlyOutPanel
                    open={panelType !== null}
                    title={
                        <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <IconButton id={"close-panel"} onClick={() => this.closeEditDialog()}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <Typography>{panelType === "security" ? <Trans>Security</Trans> : <Trans>Profile</Trans>}</Typography>
                        </span>
                    }
                    resizable={false}
                    fullScreen={true}
                >
                    {panelType && session.email && (
                        <UserProfile
                            preTeamSelection={true}
                            context={"self"}
                            type={panelType}
                            initialTabId={initialPanelTabId}
                            email={session.email}
                            onClose={this.closeEditDialog}
                            onChangesApplied={this.onProfileChanged}
                            buttonAlignment={"vertical"}
                        />
                    )}
                </FlyOutPanel>

                {(applicationInformation.loadingLoginState || applicationInformation.loadingBasicState) && (
                    <div className={"centered"} style={{ margin: 0 }}>
                        <LoadingProgress type={"linear"} label={<Trans>Loading User Information...</Trans>} />
                    </div>
                )}

                {selectTeamContainerRef?.current && (
                    <ClickAwayListener onClickAway={this.closeTeamSelectorPopover}>
                        <Popover
                            id={"popover-select-team"}
                            className={classes.popover}
                            open={teamSelectPopoverOpen}
                            onClose={(event: React.MouseEvent) => {
                                if (event.preventDefault) event.preventDefault();
                                if (event.stopPropagation) event.stopPropagation();

                                this.closeTeamSelectorPopover();
                            }}
                            anchorReference={"none"}
                            container={selectTeamContainerRef?.current}
                        >
                            <>
                                <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center", height: "3rem" }}>
                                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold", marginLeft: "0.625rem" }} noWrap>
                                        <Trans>Select a Team</Trans>
                                    </Typography>

                                    <IconButton id={"close"} style={{ marginLeft: "0.3125rem", marginRight: "0.625rem" }} onClick={this.closeTeamSelectorPopover}>
                                        <CloseIcon />
                                    </IconButton>
                                </span>

                                <FieldWrapper
                                    className={classes.field}
                                    type={"text"}
                                    name={"search"}
                                    value={search}
                                    onChange={(_name: string, value: any) => this.setSearchFilter(value as string)}
                                    options={{ placeholder: i18n._(t`Filter...`) } as TextFieldOptions}
                                    autoFocus={true}
                                />

                                {applicationInformation.memberCanCreateTeam && (
                                    <Button className={classes.button} id={"create-new-team"} type={"primary"} onClick={() => this.props.onCreate(selectedCompanyId)}>
                                        <Trans>Create New Team</Trans>
                                    </Button>
                                )}

                                <div className={classes.popoverScroller}>
                                    {filtered.length === 0 && (
                                        <Typography style={{ margin: "auto" }} noWrap>
                                            <Trans>No Matches</Trans>
                                        </Typography>
                                    )}

                                    {filtered.length > 0 &&
                                        filtered.map((team, idx) => (
                                            <MenuItem
                                                key={idx}
                                                id={team.companyId}
                                                component={"div"}
                                                className={classnames({ [classes.teamMenuItem]: true, [classes.selectedMenuItem]: selectedCompanyId === team.companyId })}
                                                selected={selectedCompanyId === team.companyId}
                                                onClick={() => {
                                                    this.closeTeamSelectorPopover();

                                                    this.setSelectedCompanyId(team.companyId, false);
                                                }}
                                            >
                                                <>
                                                    <img
                                                        style={{ flex: "0 0 auto", width: "2rem", height: "2rem", margin: "0.3125rem", borderRadius: "50%", overflow: "hidden" }}
                                                        src={team.imageUrl ? "files/portal/c/" + team.companyId + team.imageUrl : "images/teamDefault_logo.png"}
                                                        alt={"other-company"}
                                                    ></img>

                                                    <span style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", alignItems: "stretch", marginLeft: "0.3125rem", overflow: "hidden" }}>
                                                        <Typography noWrap>{team.companyName}</Typography>

                                                        <Typography style={{ fontSize: "0.75rem", fontStyle: "italic" }}>
                                                            <Trans>Account Number: {team.accountNumber}</Trans>
                                                        </Typography>
                                                    </span>
                                                </>
                                            </MenuItem>
                                        ))}
                                </div>
                            </>
                        </Popover>
                    </ClickAwayListener>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            margin: ".5rem 0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            margin: ".5rem 0.3125rem",

            textTransform: "none",
        },
        teamSelectorButton: {
            flex: "0 0 auto",

            margin: ".5rem 0.3125rem",

            textTransform: "none",

            height: "2rem",

            "& > .MuiButton-label": {
                width: "100%",
                margin: 0,

                "& > span": {
                    display: "flex",
                    alignItems: "center",

                    width: "100%",
                    height: "100%",

                    overflow: "hidden",
                },
            },
        },

        teamMenuItem: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",

            padding: "0.625rem",

            overflow: "hidden",

            "&.MuiMenuItem-root": {
                "& > img": {
                    flex: "0 0 auto",
                    width: "2rem",
                    height: "2rem",

                    marginRight: "0.3125rem",

                    borderRadius: "50%",

                    overflow: "hidden",
                },
                "& > .MuiTypography-root": {
                    flex: "1 1 auto",
                },
            },

            "&:hover": {
                backgroundColor: "var(--navigation-team-selector-hover-background-color, inherit)",
                color: "var(--navigation-team-selector-hover-color, inherit)",
            },
        },

        popover: {
            position: "absolute !important" as "absolute",

            padding: "1rem",

            "& > .MuiPopover-paper": {
                display: "flex",
                flexDirection: "column",

                width: "100%",

                minHeight: "60%",
                maxHeight: "60%",

                backgroundColor: "var(--primary-background-color, inherit)",
                color: "var(--primary-color, inherit)",
                borderColor: "var(--primary-border-color, inherit)",

                position: "absolute",
                top: "50%",
                transition: "opacity 0.5s !important",
                transformOrigin: "unset !important",
                transform: "translateY(-50%) !important",

                overflow: "hidden",
            },

            "& > div:first-child": {
                position: "absolute !important" as "absolute",
                inset: "0 0 0 0 !important",
                width: "100%",
                height: "100%",
                transition: "unset !important",
            },
        },
        popoverScroller: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflowX: "hidden",
            overflowY: "auto",
        },

        iconLinkPair: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        icon: {
            height: "2rem",
            width: "2rem",
            border: "solid 0.0625rem",
        },
        selectedMenuItem: {
            backgroundColor: "var(--navigation-team-selector-active-background-color, inherit)",
            color: "var(--navigation-team-selector-active-color, inherit)",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(SelectCompany)));
